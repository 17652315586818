<template>
  <Navbar name="categories" redirect="event.categories" />
</template>
<script>
/**
 * ==================================================================================
 * Event Category Appbar
 * ==================================================================================
 **/

import Navbar from '@/components/Navbar'

export default {
  components: {
    Navbar,
  },
}
</script>
